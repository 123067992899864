var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { width: "60%", margin: "0 auto", "min-width": "320px" } },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "demo-ruleForm",
          attrs: { model: _vm.form, "label-width": "160px" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "项目名称" } },
            [
              _c("el-input", {
                attrs: { maxlength: "64" },
                model: {
                  value: _vm.form.project,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "project", $$v)
                  },
                  expression: "form.project",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "环境" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.form.env,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "env", $$v)
                    },
                    expression: "form.env",
                  },
                },
                _vm._l(_vm.envOptions, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "集群" } },
            [
              _c("el-input", {
                attrs: { maxlength: "64" },
                model: {
                  value: _vm.form.cluster,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "cluster", $$v)
                  },
                  expression: "form.cluster",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "描述" } },
            [
              _c("el-input", {
                attrs: { maxlength: "50" },
                model: {
                  value: _vm.form.description,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "description", $$v)
                  },
                  expression: "form.description",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "配置内容" } },
            [
              _c("el-input", {
                attrs: { type: "textarea", autosize: "" },
                model: {
                  value: _vm.form.content,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "content", $$v)
                  },
                  expression: "form.content",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "校验Schema" } },
            [
              _c("el-input", {
                attrs: { type: "textarea", autosize: "" },
                model: {
                  value: _vm.form.validationSchema,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "validationSchema", $$v)
                  },
                  expression: "form.validationSchema",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "格式" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.form.format,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "format", $$v)
                    },
                    expression: "form.format",
                  },
                },
                _vm._l(_vm.formatOptions, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "灰度发布(%)" } },
            [
              _c("el-switch", {
                attrs: { "active-color": "#13ce66" },
                model: {
                  value: _vm.form.isBeta,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "isBeta", $$v)
                  },
                  expression: "form.isBeta",
                },
              }),
              _vm._v(" "),
              _c("el-input-number", {
                attrs: { min: 0, max: 100, step: 10 },
                model: {
                  value: _vm.form.ratio,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "ratio", $$v)
                  },
                  expression: "form.ratio",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.saveConfigInfo()
                    },
                  },
                },
                [_vm._v("发 布")]
              ),
              _vm._v(" "),
              _vm.form.isBeta == true
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "danger" },
                      on: {
                        click: function ($event) {
                          return _vm.delConfigInfo()
                        },
                      },
                    },
                    [_vm._v("取消灰度发布")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }